import React from 'react';

export const INFO_XML = 'POP';
export const INFO_CPV = 'Push';
export const INFO_SMART_LINK = 'Smartlink';
export const INFO_PARAM = 'param';
export const INFO_TYPE = 'type';
export const INFO_NOTE = 'note';

export const infoTypeObject = {
  [INFO_XML]: 'xmlb.digitalflare.io/feed',
  [INFO_CPV]: 'push.digitalflare.io/feed',
  [INFO_SMART_LINK]: 'xmlb.digitalflare.io/direct'
};

export const infoTypeTitlesObject = {
  [INFO_XML]: 'POP Setup',
  [INFO_CPV]: 'Push Setup',
  [INFO_SMART_LINK]: 'Smartlink Setup'
};

export const getTableInfoColumns = (handleChecked) => {
  return [
    {
      Header: '',
      id: 'selected',
      width: 40,
      Cell: (data) => {
        const { param, type, checked } = data.row.original;

        const disable = type === 'Required';

        return (
          <label>
            <input
              disabled={disable}
              type='checkbox'
              checked={checked}
              onChange={(e) => handleChecked(param, e.target.checked)}
            />
          </label>
        );
      }
    },
    {
      Header: 'Parameter',
      accessor: INFO_PARAM,
      width: 100
    },
    {
      Header: 'Type',
      accessor: INFO_TYPE,
      width: 70
    },
    {
      Header: 'Note',
      accessor: INFO_NOTE,
      width: 'auto'
    }
  ];
};

export const tableInfoInitialData = [
  {
    [INFO_PARAM]: 'feedid',
    [INFO_TYPE]: 'Required',
    [INFO_NOTE]: 'Your publisher feed id.',
    checked: true
  },
  {
    [INFO_PARAM]: 'auth',
    [INFO_TYPE]: 'Required',
    [INFO_NOTE]: 'Feed authorization code.',
    checked: true
  },
  {
    [INFO_PARAM]: 'subid',
    [INFO_TYPE]: 'Required',
    [INFO_NOTE]:
      'Traffic source id, also known as "sub id". Allowed characters [0-9A-Za-z._-]. Passing more than 5,000 distinct daily subids will significantly impact revenue optimization',
    checked: true
  },
  {
    [INFO_PARAM]: 'query',
    [INFO_TYPE]: 'Required',
    [INFO_NOTE]:
      'Keywords to look up ads for. Ommiting this field will set keywords to be "general"',
    checked: true
  },
  {
    [INFO_PARAM]: 'user_ip',
    [INFO_TYPE]: 'Required',
    [INFO_NOTE]: 'The IP address (ipv4) of the visitor.',
    checked: true
  },
  {
    [INFO_PARAM]: 'ua',
    [INFO_TYPE]: 'Required',
    [INFO_NOTE]: 'User-Agent header from visitor\'s HTTP request.',
    checked: true
  },
  {
    [INFO_PARAM]: 'uid',
    [INFO_TYPE]: 'Optional',
    [INFO_NOTE]:
      'Required for [PUSH]. can also use "uid", a unique id the visitor, best value is to provide the user advertising id.',
    checked: false
  },
  {
    [INFO_PARAM]: 'age_days',
    [INFO_TYPE]: 'Optional',
    [INFO_NOTE]:
      'Required for [PUSH]. The Age of the user since its subscription, in days (0,1,2...).',
    checked: false
  },
  {
    [INFO_PARAM]: 'age_unix',
    [INFO_TYPE]: 'Optional',
    [INFO_NOTE]:
      'Required for [PUSH]. User subscription date in Unix Timestamp, either "age_days" OR "age_unix" are required',
    checked: false
  },
  {
    [INFO_PARAM]: 'url',
    [INFO_TYPE]: 'Required',
    [INFO_NOTE]:
      'URL of the page or the referrer where ads from this feed will be displayed.',
    checked: false
  },
  {
    [INFO_PARAM]: 'domain',
    [INFO_TYPE]: 'Required',
    [INFO_NOTE]:
      'The Domain of the page where ads from this feed will be displayed. [either "url" or "domain" must be provided on a bid request',
    checked: true
  },
  {
    [INFO_PARAM]: 'lang',
    [INFO_TYPE]: 'Optional',
    [INFO_NOTE]:
      'Language of the visitor. Shall be sent in Accept-Language header format.',
    checked: false
  }
];

export const getTableInfoData = () => tableInfoInitialData;
