import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  createEndpointAction,
  updateEndpointFormDataAction,
  getInitialEndpointFormDataAction,
  updateEndpointAction
} from '../../../../actions/endpoint';

import {
  TITLE_FIELD,
  ENDPOINT_WIZARD,
  ENDPOINTS_DEMAND_FEEDS_FORM,
  ENDPOINTS_FALLBACK_FORM,
  GENERAL_FORM,
  OPTIMIZATION_FORM,
  PROTECTION_FORM,
  TARGETING_FORM,
  PARTNER_FIELD
} from '../../constants';

import { validateEndpointFormData } from '../../validators/endpoint';

import Wizard from '../../wizard-base/Wizard';

import WizardGeneralForm from '../shared/forms/WizardGeneralForm';
import WizardOptimizationForm from '../shared/forms/WizardOptimizationFrom';
import WizardProtectionForm from '../shared/forms/WizardProtectionForm';
import WizardTargetingForm from '../shared/forms/WizardTargetingForm';
import EndpointDemandFeeds from './forms/EndpointDemandFeeds';
import EndpointFallbackForm from './forms/EndpointFallbackForm';

const EndpointWizard = () => {
  const {
    title,
    id,
    partner
  } = useSelector(
    state => ({
      title: state[ENDPOINT_WIZARD].formData[TITLE_FIELD],
      id: state[ENDPOINT_WIZARD].formData._id,
      partner: state[ENDPOINT_WIZARD].formData[PARTNER_FIELD]
    })
  );

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    let error;

    if (id) {
      error = await updateEndpointAction()(dispatch);
    } else {
      error = await createEndpointAction()(dispatch);
    }

    if (error) {
      // TODO Handle error
      return false;
    }

    return true;
  };

  const handleTitle = async (event) => {
    const title = event.target.value;

    updateEndpointFormDataAction({ title })(dispatch);
  };

  const stepsForms = [
    {
      label: GENERAL_FORM,
      component: WizardGeneralForm,
      parentWizard: ENDPOINT_WIZARD
    },
    {
      label: OPTIMIZATION_FORM,
      component: WizardOptimizationForm,
      parentWizard: ENDPOINT_WIZARD
    },
    {
      label: TARGETING_FORM,
      component: WizardTargetingForm,
      parentWizard: ENDPOINT_WIZARD
    },
    {
      label: PROTECTION_FORM,
      component: WizardProtectionForm,
      parentWizard: ENDPOINT_WIZARD
    },
    {
      label: ENDPOINTS_FALLBACK_FORM,
      component: EndpointFallbackForm
    },
    {
      label: ENDPOINTS_DEMAND_FEEDS_FORM,
      component: EndpointDemandFeeds
    }
  ];

  return (
    <Wizard
      clearFormState={getInitialEndpointFormDataAction}
      stepsForms={stepsForms}
      handleSubmit={handleSubmit}
      updateTitle={handleTitle}
      title={title}
      id={id}
      validateForms={validateEndpointFormData}
      partner={partner}
      titlePlaceholder={`${id ? 'Update' : 'New'} Endpoint`}
      wizardName={`${id ? 'Update' : 'New'} Endpoint`}
    />
  );
};

export default EndpointWizard;
