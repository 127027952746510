import * as yup from 'yup';

import {
  BID_MODIFY_FIELD,
  LIMITS_FIELD,
  MAX_BID_FIELD,
  MIN_BID_FIELD,
  VALUE_FIELD,
  GEOS_FIELD,
  REV_SHARE_FIELD,
  REDIRECT_DOMAINS_FIELD,
  DMS_DB_FIELD,
  SUB_ID_DB_FIELD,
  REQUEST_TIMEOUT_FIELD,
  CLICK_LIMITS_FIELD
} from '../../constants';
import { multiSelectSchema, requiredMultiSelectSchema } from './shared-schemas';

export const emptyStringToNull = (value, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }

  return value;
};

export const optimizationArrayObjectSchema = yup.array().of(
  yup.object()
    .shape({
      [VALUE_FIELD]: yup.number().required('Value is required').transform(emptyStringToNull).nullable(),
      [GEOS_FIELD]: requiredMultiSelectSchema
    })
);

export const optimizationSchema = yup.object().shape({
  [REQUEST_TIMEOUT_FIELD]: yup.number()
    .required('Request timeout is required')
    .min(100, 'Minimum 100ms')
    .max(2000, 'Maximum 2000ms')
    .transform(emptyStringToNull).nullable(),
  [MIN_BID_FIELD]: yup.number().required('Min Bid is required').transform(emptyStringToNull).nullable(),
  [MAX_BID_FIELD]: yup.number().required('Max Bid is required').transform(emptyStringToNull).nullable(),
  [BID_MODIFY_FIELD]: optimizationArrayObjectSchema,
  [LIMITS_FIELD]: optimizationArrayObjectSchema,
  [CLICK_LIMITS_FIELD]: optimizationArrayObjectSchema,
  [REDIRECT_DOMAINS_FIELD]: multiSelectSchema,
  [DMS_DB_FIELD]: multiSelectSchema,
  [SUB_ID_DB_FIELD]: multiSelectSchema,
  [REV_SHARE_FIELD]: optimizationArrayObjectSchema.required('Rev share is required').min(1, 'At least 1 element is required')
});

export const validateOptimizationData = (data) => {
  try {
    optimizationSchema.validateSync(data);

    return true;
  } catch (e) {
    return false;
  }
};
