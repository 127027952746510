import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';

import { INFO_NOTE, INFO_PARAM, INFO_TYPE, infoTypeTitlesObject } from './helpers/table-info-helpers';

const styles = StyleSheet.create({
  body: {
    padding: 15,
    fontSize: 12
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    margin: 10
  },
  tableRow: {
    flexDirection: 'row'
  },
  tableColHeader: {
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableParam: {
    width: '20%'
  },
  tableType: {
    width: '15%'
  },
  tableNote: {
    width: '65%'
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: 5,
    fontSize: 10
  },
  typeBox: {
    margin: 10
  },
  typeText: {
    fontFamily: 'Helvetica-Bold',
    marginBottom: 10
  },
  codeBox: {
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    padding: 10,
    margin: '0 10px 10px 10px'
  },
  logoBox: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 15
  },
  logo: {
    width: 100
  },
  title: {
    alignSelf: 'center',
    fontFamily: 'Helvetica-Bold',
    fontSize: 14,
    marginTop: 1
  }
});

const TableInfoPdf = ({
  name,
  type,
  code,
  data
}) => (
  <Document>
    <Page style={styles.body}>
      <View style={styles.logoBox}>
        <Image style={styles.logo} source={require('../../../assets/images/logo@2x.png')}/>
        <Text style={styles.title}>&nbsp;Integration Manual</Text>
      </View>

      <View style={styles.typeBox}>
        <Text style={styles.typeText}>Endpoint: {name}</Text>
      </View>

      <View style={styles.typeBox}>
        <Text style={styles.typeText}>{infoTypeTitlesObject[type]}</Text>
      </View>

      <View style={styles.codeBox}>
        <Text>{code}</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={{ ...styles.tableColHeader, ...styles.tableParam }}>
            <Text style={styles.tableCellHeader}>Parameter</Text>
          </View>
          <View style={{ ...styles.tableColHeader, ...styles.tableType }}>
            <Text style={styles.tableCellHeader}>Type</Text>
          </View>
          <View style={{ ...styles.tableColHeader, ...styles.tableNote }}>
            <Text style={styles.tableCellHeader}>Note</Text>
          </View>
        </View>
        {data.map((item, index) => (
          <View key={index} style={styles.tableRow}>
            <View style={{ ...styles.tableCol, ...styles.tableParam }}>
              <Text style={styles.tableCell}>{item[INFO_PARAM]}</Text>
            </View>
            <View style={{ ...styles.tableCol, ...styles.tableType }}>
              <Text style={styles.tableCell}>{item[INFO_TYPE]}</Text>
            </View>
            <View style={{ ...styles.tableCol, ...styles.tableNote }}>
              <Text style={styles.tableCell}>{item[INFO_NOTE]}</Text>
            </View>
          </View>
        ))}
      </View>
    </Page>
  </Document>
);

TableInfoPdf.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  code: PropTypes.string.isRequired
};

export default TableInfoPdf;
