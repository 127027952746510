import { PDFDownloadLink } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTable, useFlexLayout } from 'react-table';

import {
  getTableInfoColumns,
  INFO_PARAM,
  INFO_XML,
  infoTypeObject,
  infoTypeTitlesObject,
  tableInfoInitialData
} from './helpers/table-info-helpers';

import TableInfoPdf from './TableInfoPdf';

import './TableColumnsInfo.scss';

const TableColumnsInfo = ({ name, id, auth, handleClose }) => {
  const divRef = useRef(null);
  // TODO When change type use getTableInfoData(we need other parameters)
  const [type, setType] = useState(INFO_XML);
  const [generatedUrl, setGeneratedUrl] = useState(
    `https://${infoTypeObject[type]}?feedid=${id}&auth=${auth}`
  );
  const [items, setItems] = useState(tableInfoInitialData);

  const handleClickOutside = (e) => {
    if (!divRef.current.contains(e.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const handleSelectItems = (param, checked) => {
    setItems((state) =>
      state.map((item) => {
        if (item[INFO_PARAM] === param) {
          item.checked = checked;
        }

        return item;
      })
    );
  };

  useEffect(() => {
    let url = `https://${infoTypeObject[type]}?feedid=${id}&auth=${auth}`;

    items.map((item) => {
      if (item[INFO_PARAM] !== 'feedid' && item[INFO_PARAM] !== 'auth') {
        if (item.checked) {
          url += `&${item[INFO_PARAM]}={${item[INFO_PARAM]}}`;
        }
      }
    });

    setGeneratedUrl(url);
  }, [JSON.stringify(items), type]);

  const data = useMemo(() => items, [JSON.stringify(items)]);
  const columns = useMemo(() => getTableInfoColumns(handleSelectItems), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable(
    {
      columns,
      data
    },
    useFlexLayout
  );

  return (
    <div className='table-columns-info-box' ref={divRef}>
      <div className='table-info-title'>
        <h5>Integration Info</h5>
        <p className='btn-close'>
          <span onClick={handleClose} />
        </p>
      </div>

      <div className='table-info-subtitle'>
        <h6>{type} feed URL Template</h6>
      </div>
      <div className='table-info-tabs'>
        {Object.keys(infoTypeTitlesObject).map((key, index) => (
          <button
            key={index}
            onClick={() => setType(key)}
            className={`table-info-item ${type === key ? 'active-type' : ''}`}
          >
            {infoTypeTitlesObject[key]}
          </button>
        ))}
      </div>

      <div className='table-info-url'>{generatedUrl}</div>

      <h6>Parameters</h6>

      <div className='table-info-params'>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th key={index} scope='col' {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);

              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className='table-info-p-detail'>
        All parameters should be URL-encoded.
      </div>

      <div className='table-info-download'>
        <PDFDownloadLink
          document={
            <TableInfoPdf
              name={`${name} (${id})`}
              type={type}
              data={data}
              code={generatedUrl}
            />
          }
          fileName={`digital-flare-integration-manual-endpoint-${id}.pdf`}
        >
          {({ loading }) => (
            <button className='second-btn btn btn-dark-violet'>
              {loading ? 'Loading document...' : 'Download pdf'}
            </button>
          )}
        </PDFDownloadLink>
      </div>
    </div>
  );
};

TableColumnsInfo.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  auth: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default TableColumnsInfo;
